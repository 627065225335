import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  CharityEffects,
  CoreModule,
  DonationEffects,
  ItemEffects,
  PartnerEffects,
  reducerProvider,
  reducerToken,
  StoreEffects,
  TruckEffects,
  UsersEffects,
} from '@core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { RsplApiModule } from '@rspl-api';
import { RsplAuthModule } from '@rspl-auth';
import { RsplMapModule } from '@rspl-map';
import { RsplTableModule } from '@rspl-table';
import { RsplUIModule } from '@rspl-ui';
import { RsplVersionConfig, RsplVersionModule } from '@rspl-version';
import { environment } from '@zendesk/../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CoreModule.forRoot(environment),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    EffectsModule.forRoot([
      DonationEffects,
      CharityEffects,
      UsersEffects,
      StoreEffects,
      PartnerEffects,
      TruckEffects,
      ItemEffects,
    ]),
    StoreModule.forRoot(reducerToken),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      connectInZone: true,
    }),
    RsplUIModule.forRoot(),
    RsplMapModule.forRoot(),
    RsplVersionModule.forRoot({
      currentHash: '{{POST_BUILD_ENTERS_HASH_HERE}}',
    } as RsplVersionConfig),
    RsplTableModule.forRoot(),
    RsplApiModule.forRoot(),
    RsplAuthModule.forRoot(),
  ],
  providers: [reducerProvider, { provide: MAT_DATE_LOCALE, useValue: 'en' }],
  bootstrap: [AppComponent],
})
export class AppModule {}
